const universalModalRoot = document.getElementById(window.UNIVERSAL_MODAL.ROOT);

/**
 * Fires a custom event that will be listened by universal modal.
 * @param {string} action - Action to open or close the modal
 * @param {string} type - Type of modal content i.e. MEDIA_ITEM
 * @param {Object} payload - Object that will be passed to modal listener through detail.
 */
const fireUniversalModalEvent = (
  action = window.UNIVERSAL_MODAL.OPEN,
  type = window.UNIVERSAL_MODAL.TYPE.MEDIA_ITEM,
  payload = {},
) => {
  if (!universalModalRoot) {
    return;
  }

  let event;
  // Modern browsers
  if (typeof Event === 'function') {
    event = new CustomEvent(window.UNIVERSAL_MODAL.EVENT_NAME, {
      bubbles: true,
      detail: {
        action,
        type,
        ...payload,
      },
    });
  // <= IE 11
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(window.UNIVERSAL_MODAL.EVENT_NAME, true, true, {
      action,
      type,
      ...payload,
    });
  }

  if (event) {
    universalModalRoot.dispatchEvent(event);
  }
};

/**
 * Used to transform payload
 * transforms ids and activeId to items array
 * @param {Object} payload - modal payload
 * @param {String[]} payload.ids - Media Item Attribution ids that will be displayed.
 * @param {String} payload.activeId - active Media Item Attribution id.
 * @returns {Object} preparedPayload to be sent to universal modal
 */
const preparePayload = (payload) => {
  const preparedPayload = { ...(payload || {}) };

  if ('ids' in preparedPayload) {
    preparedPayload.items = preparedPayload.ids.map(id => ({
      id,
      isActive: id === preparedPayload.activeId,
    }));
    delete preparedPayload.ids;
  }
  if ('activeId' in preparedPayload) {
    delete preparedPayload.activeId;
  }

  return preparedPayload;
};

/**
 * Use it to open the universal modal from any where in the website.
 * Called by window.openMediaUniversalModal
 * @param {Object} payload - data that will be passed to UniversalModal's listener
 * it has to contain items
 * @param {String[]} payload.ids - Media Item Attribution ids that will be displayed.
 * @param {String} payload.activeId - active Media Item Attribution id.
 * @param {Object} payload.extraAnalyticsInfo - data that will be sent to Segment
 * along tracking any event.
 */
const openMediaUniversalModal = (payload) => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.OPEN,
    window.UNIVERSAL_MODAL.TYPE.MEDIA_ITEM,
    preparePayload(payload),
  );
};

/**
 * Use it to close the universal modal from any where in the website.
 * Called by window.closeMediaUniversalModal
 */
const closeMediaUniversalModal = () => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.CLOSE,
    window.UNIVERSAL_MODAL.TYPE.MEDIA_ITEM,
  );
};

/**
 * Use it to open brand photo universal modal from any where in the website.
 * Called by window.openBrandPhotoUniversalModal
 * @param {Object} payload - data that will be passed to UniversalModal's listener
 * it has to contain items
 * @param {String} payload.brandSlug - Brand slug that owns this photos.
 * @param {String[]} payload.ids - Media Item Attribution ids that will be displayed.
 * @param {String} payload.activeId - active Media Item Attribution id.
 * @param {Object} payload.extraAnalyticsInfo - data that will be sent to Segment
 * along tracking any event.
 */
const openBrandPhotoUniversalModal = (payload) => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.OPEN,
    window.UNIVERSAL_MODAL.TYPE.BRAND_PHOTO,
    preparePayload(payload),
  );
};

/**
 * Use it to close the brand photo universal modal from any where in the website.
 * Called by window.closeBrandPhotoUniversalModal
 */
const closeBrandPhotoUniversalModal = () => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.CLOSE,
    window.UNIVERSAL_MODAL.TYPE.BRAND_PHOTO,
  );
};

/**
 * Use it to open collections popup from any where in the website.
 * Called by window.openAddToCollectionPopup
 * @param {Object} payload - data that will be passed to UniversalModal's listener
 * @param {Int} payload.mediaAttrId - media attribution that will be added to the collections.
 */
const openAddToCollectionPopup = (payload) => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.OPEN,
    window.UNIVERSAL_MODAL.TYPE.ADD_TO_COLLECTION,
    preparePayload(payload),
  );
};

/**
 * Use it to close the collection popup universal modal from any where in the website.
 * Called by window.closeAddToCollectionPopup
 */
const closeAddToCollectionPopup = () => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.CLOSE,
    window.UNIVERSAL_MODAL.TYPE.ADD_TO_COLLECTION,
  );
};

/**
 * Use it to open media credit popup from any where in the website.
 * Called by window.openMediaCreditPopup
 * @param {Object} payload - data that will be passed to UniversalModal's listener
 * @param {Array<Int>} payload.mediaIds - media item ids that the user wants to manage
 * photographers for.
 */
const openMediaCreditPopup = (payload) => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.OPEN,
    window.UNIVERSAL_MODAL.TYPE.MEDIA_CREDIT,
    payload,
  );
};

/**
 * Use it to close media credit popup universal modal from any where in the website.
 * Called by window.closeMediaCreditPopup
 */
const closeMediaCreditPopup = () => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.CLOSE,
    window.UNIVERSAL_MODAL.TYPE.MEDIA_CREDIT,
  );
};

const openBrandMessagePopup = (payload) => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.OPEN,
    window.UNIVERSAL_MODAL.TYPE.BRAND_MESSAGE,
    payload,
  );
};

const closeBrandMessagePopup = () => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.CLOSE,
    window.UNIVERSAL_MODAL.TYPE.BRAND_MESSAGE,
  );
};

/**
 * Use it to open the universal modal from any where in the website.
 * Called by window.openCollectionUniversalModal
 * @param {Object} payload - data that will be passed to UniversalModal's listener
 * it has to contain items
 * @param {String[]} payload.ids - Media Item Attribution ids that will be displayed.
 * @param {String} payload.activeId - active Media Item Attribution id.
 * @param {Object} payload.extraAnalyticsInfo - data that will be sent to Segment
 * along tracking any event.
 */
const openCollectionUniversalModal = (payload) => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.OPEN,
    window.UNIVERSAL_MODAL.TYPE.COLLECTIONS,
    preparePayload(payload),
  );
};

/**
 * Use it to close the universal modal from any where in the website.
 * Called by window.closeCollectionUniversalModal
 */
const closeCollectionUniversalModal = () => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.CLOSE,
    window.UNIVERSAL_MODAL.TYPE.COLLECTION,
  );
};

/**
 * Use it to close all universal modals.
 * Called by window.closeUniversalModal
 */
const closeUniversalModals = () => {
  [
    window.closeMediaUniversalModal,
    window.closeBrandPhotoUniversalModal,
    window.closeAddToCollectionPopup,
    window.closeCollectionUniversalModal,
    // Add other close functions methods here
  ].forEach(closeFn => typeof closeFn === 'function' && closeFn());
};

/**
 * Use it to open NotFound popup from any where in the website.
 * Called by window.openNotFoundPopup
 */
const openNotFoundPopup = (payload) => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.OPEN,
    window.UNIVERSAL_MODAL.TYPE.NOT_FOUND,
    preparePayload(payload),
  );
};

const closeNotFoundPopup = () => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.CLOSE,
    window.UNIVERSAL_MODAL.TYPE.NOT_FOUND,
  );
};

/**
 * Use it to open GenericMessage popup from any where in the website.
 * Called by window.openGenericMessagePopup
 */
const openGenericMessagePopup = (payload) => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.OPEN,
    window.UNIVERSAL_MODAL.TYPE.GENERIC_MESSAGE,
    preparePayload(payload),
  );
};

const closeGenericMessagePopup = () => {
  fireUniversalModalEvent(
    window.UNIVERSAL_MODAL.CLOSE,
    window.UNIVERSAL_MODAL.TYPE.GENERIC_MESSAGE,
  );
};

/**
 * Assigned to window to be available any where in the website.
 */
window.closeUniversalModals = closeUniversalModals;
window.openMediaUniversalModal = openMediaUniversalModal;
window.closeMediaUniversalModal = closeMediaUniversalModal;
window.openBrandPhotoUniversalModal = openBrandPhotoUniversalModal;
window.closeBrandPhotoUniversalModal = closeBrandPhotoUniversalModal;
window.openAddToCollectionPopup = openAddToCollectionPopup;
window.closeAddToCollectionPopup = closeAddToCollectionPopup;
window.openMediaCreditPopup = openMediaCreditPopup;
window.closeMediaCreditPopup = closeMediaCreditPopup;
window.openBrandMessagePopup = openBrandMessagePopup;
window.closeBrandMessagePopup = closeBrandMessagePopup;
window.openCollectionUniversalModal = openCollectionUniversalModal;
window.closeCollectionUniversalModal = closeCollectionUniversalModal;

window.openNotFoundPopup = openNotFoundPopup;
window.closeNotFoundPopup = closeNotFoundPopup;
window.openGenericMessagePopup = openGenericMessagePopup;
window.closeGenericMessagePopup = closeGenericMessagePopup;
